
export default {
  data () {
    return {

    }
  },
  created () {
  },
  computed: {
    currentSlug () {
      return this.$store.getters.slug
    }
  },
  methods: {

  },

  beforeDestroy () {
    this.$refs.card.hide()
  },
  watch: {
    contentJson () {
      this.$nextTick(() => {
        // this.$refs.card.show()
      })
    }
  }

}
