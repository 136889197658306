<template>
  <div ref="canvas" class="canvas" @touchstart="onTouchStart" @mouseenter="onMouseEnter" @mouseleave="onMouseLeave" @mousemove="onMouseMove" @click="onClick">
  </div>
</template>

<script>
import gsap from 'gsap'
import * as PIXI from 'pixi.js'

import { RGBSplitFilter } from '@pixi/filter-rgb-split'
import { KawaseBlurFilter } from '@pixi/filter-kawase-blur'
import { BulgePinchFilter } from '@pixi/filter-bulge-pinch'

export default {
  name: 'Canvas',
  props: {
    image: undefined
  },
  data () {
    return {
      app: null,
      container: null,
      timeline: null,
      blur: null,
      noise: null,
      pinch: null,
      center: { x: 0, y: 0 },
      downloadBG: undefined,
      spriteURL: undefined,
      randomFilter: 0,
      mask: undefined,
      rgbSplit: undefined,
      userTexture: undefined,
      userSprite: undefined,
      mouse: undefined,
      touch: undefined,
      touchEnd: undefined
    }
  },
  mounted () {
    if (window.innerWidth <= 576) return

    this.app = new PIXI.Application({
      backgroundAlpha: 0,

      antialias: true
    })

    this.$el.appendChild(this.app.view)
    this.app.renderer.view.style.display = 'block'
    this.app.renderer.autoResize = true

    this.container = new PIXI.Container()
    this.app.stage.addChild(this.container)

    // this.container.anchor.setTo(0.5, 0.5)

    this.blur = new KawaseBlurFilter()
    this.blur.blur = 20
    this.blur.quality = 10

    this.noise = new PIXI.filters.NoiseFilter()
    this.noise.noise = 0.05
    this.noise.seed = Math.random()

    this.pinch = new BulgePinchFilter({ center: [0, 0], radius: 400, strength: 0 })
    // create all filters, rgb split and glitch slices
    this.rgbSplit = new RGBSplitFilter()
    this.container.filters = [this.rgbSplit, this.blur, this.noise, this.pinch]

    // reset rgb split
    this.rgbSplit.red.x = 0
    this.rgbSplit.red.y = 0
    this.rgbSplit.green.x = 0
    this.rgbSplit.green.y = 0
    this.rgbSplit.blue.x = 0
    this.rgbSplit.blue.y = 0

    this.animate()
    this.animateNoise()
  },
  watch: {
    image () {
      this.mask = new PIXI.Graphics()
      this.mask.beginFill(0x660000)
      this.mask.drawRect(0, 0, 600, 981)
      this.mask.endFill()

      const user = PIXI.Sprite.from(this.image)
      user.mask = this.mask

      user.texture.baseTexture.on('loaded', () => {
        let startX = user.width * 0.5 - 300
        let startY = user.height * 0.5 - 465

        // let useWidth = 600
        // let useHeight = 981

        if (user.width < 640) {
          // useWidth = user.width - 40
          // useHeight = user.width - 40

          // console.log(useWidth, useHeight)

          const scale = 640 / user.width
          // scale up
          startX = 0
          startY = 0

          user.scale.x = scale
          user.scale.y = scale
        }

        const subTexture = new PIXI.Texture(user.texture.baseTexture, new PIXI.Rectangle(startX, startY, 600, 981))
        this.userSprite = PIXI.Sprite.from(subTexture)

        this.container.addChild(this.userSprite)
        this.container.removeChild(user)
      })

      this.container.addChild(user)
    }
  },
  methods: {
    randomizeFilter () {
      this.randomFilter = Math.random()
    },
    downloadURI (uri, name) {
      const link = document.createElement('a')
      link.download = name
      link.href = uri
      link.click()
    },
    onClick () {
      this.$gtm.trackEvent({
        event: null, // Event type [default = 'interaction'] (Optional)
        category: 'Easteregg',
        action: 'click',
        label: 'Easteregg Download',
        value: 420,
        noninteraction: false // Optional
      })

      const renderer = this.app.renderer

      const bg = this.spriteURL
      // if (this.image) bg = this.image

      const sprite = PIXI.Sprite.from(bg)
      sprite.x = 20
      sprite.y = 20

      sprite.addChild(this.container)
      sprite.addChild(this.container)
      this.downloadBG.addChild(sprite)

      renderer.plugins.extract.canvas(this.downloadBG).toBlob((b) => {
        const data = b
        const type = 'image/png'
        const name = 'marcstorch.com.png'

        const blob = new Blob([data], { type })
        const url = window.URL.createObjectURL(blob)
        this.downloadURI(url, name)
        window.URL.revokeObjectURL(url)

        this.container.x = 0
        this.container.y = 0
        this.container.scale.x = 1
        this.container.scale.y = 1
        this.container.mask = null

        this.app.stage.addChild(this.container)

        sprite.destroy()
      }, 'image/png')
    },
    onMouseMove (e, touch) {
      const w = this.$el.getBoundingClientRect().width
      const h = this.$el.getBoundingClientRect().height

      if (!e && touch) {
        e = {}
        e.offsetX = touch.x
        e.offsetY = touch.y
      }

      gsap.to(this.center, {
        x: Math.min(e.offsetX / w, 1),
        y: Math.min(e.offsetY / h, 1),
        onUpdate: () => {
          this.pinch.center = [this.center.x, this.center.y]
        }
      })
    },
    onMouseEnter (e) {
      if (e) this.mouse = true
      const w = Math.round(this.$el.getBoundingClientRect().width) * 2

      const random = Math.random()

      if (random >= 0 && random < 0.2) {
        gsap.to(this.pinch, { radius: 200, strength: 0.3 })
      } else if (random > 0.2 && random < 0.4) {
        gsap.to(this.pinch, { radius: w / 2, strength: -0.4 })
      } else if (random > 0.4 && random < 0.6) {
        gsap.to(this.pinch, { radius: w / 3, strength: 0.2 })
      } else if (random > 0.6 && random < 0.8) {
        gsap.to(this.pinch, { radius: w, strength: -0.3 })
      } else if (random > 0.8 && random <= 1) {
        gsap.to(this.pinch, { radius: 100, strength: 0.4 })
      }
    },
    onMouseLeave () {
      gsap.to(this.pinch, { radius: 0, strength: 0 })
    },

    onTouchStart () {
      if (this.mouse) return

      if (this.touchEnd) clearTimeout(this.touchEnd)
      this.touchEnd = undefined

      this.onMouseEnter()
      this.$refs.canvas.addEventListener('touchmove', this.onTouchMove)
      this.$refs.canvas.addEventListener('touchend', this.onTouchEnd)
    },
    onTouchMove (e) {
      if (e.touches[0]) {
        console.log(e.touches[0])
        const rect = e.touches[0].target.getBoundingClientRect()
        this.onMouseMove(undefined, { x: e.touches[0].clientX - rect.left, y: e.touches[0].clientY - rect.top - 40 })
      }
    },
    onTouchEnd (e) {
      if (this.touchEnd) clearTimeout(this.touchEnd)
      this.touchEnd = undefined

      this.touchEnd = setTimeout(this.onMouseLeave, 2500)

      this.$refs.canvas.removeEventListener('touchmove', this.onTouchMove)
      this.$refs.canvas.removeEventListener('touchend', this.onTouchEnd)
    },
    animateNoise () {
      gsap.to(this.noise, {
        seed: Math.random(),
        onComplete: () => {
          this.animateNoise()
        }
      })
    },
    animate () {
      const tl = gsap.timeline({
        delay: this.randomIntFromInterval(0, 3),
        onComplete: () => {
          this.animate()
        }
      })

      this.timeline = tl

      tl.to(this.rgbSplit.red, {
        duration: 0.2,
        x: this.randomIntFromInterval(-2, 2),
        y: 0
      })

      tl.to(this.rgbSplit.red, {
        duration: 0.01,
        x: 0,
        y: 0
      })

      tl.to(this.rgbSplit.green, {
        duration: 0.2,
        x: this.randomIntFromInterval(-2, 2),
        y: 0
      }, '-=0.4')

      tl.to(this.rgbSplit.green, {
        duration: 0.1,
        x: this.randomIntFromInterval(-5, 5),
        y: 0
      })

      tl.to(this.rgbSplit.green, {
        duration: 0.01,
        x: 0,
        y: 0
      })

      tl.timeScale(1)
    },
    randomIntFromInterval (min, max) {
      return Math.random() * (max - min + 1) + min
    },
    load (url) {
      if (!this.app) return

      const sprite = PIXI.Sprite.from(url)
      this.spriteURL = url

      sprite.texture.baseTexture.on('loaded', () => {
        this.onImageLoaded(sprite)
      })

      this.container.addChild(sprite)

      if (sprite.width > 1) this.onImageLoaded(sprite)
    },
    onImageLoaded (sprite) {
      this.downloadBG = PIXI.Sprite.from('assets/img/profil_download.jpg')
      this.randomizeFilter()

      this.app.renderer.resize(sprite.width, sprite.height)
      gsap.to(this.blur, { ease: 'Quad.easeOut', duration: 1.5, blur: 0 }).then(() => {
        this.container.filters = [this.rgbSplit, this.noise, this.pinch]
      })
    }
  },
  beforeDestroy () {
    if (this.app) {
      if (this.touchEnd) clearTimeout(this.touchEnd)
      this.touchEnd = undefined

      this.app.destroy()
      this.app = null

      this.timeline.kill()
      this.timeline = null

      gsap.killTweensOf(this.blur)
      gsap.killTweensOf(this.noise)

      this.$refs.canvas.removeEventListener('touchmove', this.onTouchMove)
      this.$refs.canvas.removeEventListener('touchend', this.onTouchEnd)
    }
  }
}
</script>
