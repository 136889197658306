<template>
  <div class="home" v-if="contentJson">
    <Card ref="card">
      <div class="home__content">
        <TickerHeadline>{{getRandomGreeting()}}</TickerHeadline>
        <p>
          I am Marc, Frontend Developer from Berlin.<br>
          I am a specialist in creating custom digital products.
          <br><br>
          Since 2013 you can hire me as a freelancer.
        </p>

        <Button target="/about">ABOUT</Button>
        <Button target="/projects">PROJECTS</Button>
        <Button target="/contact">CONTACT</Button>
      </div>

      <ProfilePicture></ProfilePicture>
    </Card>
  </div>
</template>

<script>
import Card from '@/components/Card'
import TickerHeadline from '@/components/TickerHeadline'
import Button from '@/components/Button'
import ProfilePicture from '../components/ProfilePicture'
import DataMixin from '@/mixins/DataMixin'
import AppearMixin from '@/mixins/AppearMixin'
export default {
  name: 'Home',
  metaInfo: {
    title: 'MARCSTORCH.COM'
  },
  components: {
    Card,
    Button,
    ProfilePicture,
    TickerHeadline
  },
  methods: {
    getRandomGreeting () {
      const hello = ['GUDE', 'HELLO', 'MOIN']
      return hello[Math.floor(hello.length * Math.random())]
    }
  },
  mixins: [DataMixin, AppearMixin],
  created () {
    this.fetchData('home')
  }
}
</script>
