<template>
  <div class="home__picture" v-view="viewHandler">
    <Canvas :image="image" ref="canvas"></Canvas>
    <!--<input type='file' ref="upload" @change="onFileChange"/>
    <img src="" alt="" ref="image">-->
  </div>
</template>

<script>
// import gsap from 'gsap'
import Canvas from '@/components/Canvas'
export default {
  name: 'ProfilePicture',
  components: { Canvas },
  data () {
    return {
      image: undefined
    }
  },
  methods: {

    viewHandler (e) {
    },
    onFileChange (e) {
      const img = this.$refs.image
      // console.log(this.$refs.image)

      img.onload = () => {
        this.image = this.$refs.image.src

        // URL.revokeObjectURL(img.src) // no longer needed, free memory
      }

      img.src = URL.createObjectURL(e.srcElement.files[0]) // set src to blob url
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.$refs.canvas.load('assets/img/profil.jpg')
    })
  }

}
</script>
